import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './globalStyles';
import theme from './theme';
import { StyledTaskbar, TaskbarButton, TaskbarIcons, TaskbarRight } from './TaskbarStyles';
import BackgroundImage from './BackgroundImage';

import Animations from './assets/icons/Animations.png';
import Twitter from './assets/icons/Twitter.png';
import Instagram from './assets/icons/Instagram.png';
import Memes from './assets/icons/Memes.png';
import RoadMap from './assets/icons/RoadMap.png';
import Telegram from './assets/icons/Telegram.png';
import Jeet from './assets/icons/Jeet.png';
import Dao from './assets/icons/DAO.png';
import Dexscreener from './assets/icons/Dexscreener.png';
import Merch from './assets/icons/Merch.png';

import Shell from './assets/icons/Shell.png';
import Search from './assets/icons/Search.png';

import ModalWindow from './window/ModalWindow';
import Animation from './window/Animation';
import Meme from './window/Meme';
import RoadMa from './window/RoadMa';
import StartMenu from './StartMenu';
import DoodleJumpWindow from './window/DoodleJumpWindow';

const App = () => {
  const [windows, setWindows] = useState([]);
  const [startMenuOpen, setStartMenuOpen] = useState(false);
  const [walletConnected, setWalletConnected] = useState(false);
  const [publicKey, setPublicKey] = useState('');

  const openApp = (component, title, initialDimensions = { width: 300, height: 300 }) => {
    setStartMenuOpen(false);
    setWindows([...windows, { content: component, title: title, id: windows.length, initialDimensions }]);
  };

  const closeWindow = (id) => {
    setWindows(windows.filter((window) => window.id !== id));
  };

  const toggleStartMenu = () => {
    setStartMenuOpen(!startMenuOpen);
  };

  const connectWallet = async () => {
    try {
      if (window.solana && window.solana.isPhantom) {
        const resp = await window.solana.connect();
        setPublicKey(resp.publicKey.toString());
        setWalletConnected(true);
        console.log('Connected with Phantom Wallet, Public Key:', resp.publicKey.toString());
      } else {
        alert('No compatible Solana wallet found. Please install Phantom from https://phantom.app.');
      }
    } catch (err) {
      console.error('Error connecting to wallet:', err);
    }
  };

  useEffect(() => {
    if (window.solana && window.solana.isPhantom) {
      window.solana.on('connect', () => console.log('Phantom Wallet connected!'));
    }
  }, []);

  return (
    <div>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <BackgroundImage />
        <StyledTaskbar>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TaskbarButton onClick={toggleStartMenu}>
              <img src={Shell} alt="Start" /> Start
            </TaskbarButton>
            <TaskbarIcons>
              <a href="https://jup.ag/swap/SOL-3FyA9YfRvyQF7KpEtsHwccmbkNjpG15D7aWsjBstfkJ2" target="_blank" rel="noopener noreferrer">
                <img src={Search} alt="Search" />
              </a>
            </TaskbarIcons>
          </div>
          <TaskbarRight>
            <span>SuperSite V1.3</span>
            <button onClick={connectWallet} style={{ marginLeft: '20px', padding: '5px 10px', cursor: 'pointer' }}>
              {walletConnected ? `Connected: ${publicKey.substring(0, 6)}...${publicKey.substring(publicKey.length - 4)}` : 'Connect Wallet'}
            </button>
          </TaskbarRight>
        </StyledTaskbar>
        {startMenuOpen && <StartMenu onOpenApp={(app) => openApp(app.component, app.title)} />}
        <div className="icon-container">
          <div className="icon-row">
            <div className="desktop-icon" onClick={() => openApp(<Animation />, 'Animation')}>
              <img src={Animations} alt="Animations" />
              <p>Animations</p>
            </div>
            <div className="desktop-icon">
              <a href="https://twitter.com/SUPERSSOLANA" target="_blank" rel="noopener noreferrer">
                <img src={Twitter} alt="Twitter" />
                <p>Twitter</p>
              </a>
            </div>
            <div className="desktop-icon">
              <a href="https://www.instagram.com/superscommunity" target="_blank" rel="noopener noreferrer">
                <img src={Instagram} alt="Instagram" />
                <p>Instagram</p>
              </a>
            </div>
          </div>

          <div className="icon-row">
            <div className="desktop-icon" onClick={() => openApp(<RoadMa />, 'RoadMap')}>
              <img src={RoadMap} alt="RoadMap" />
              <p>RoadMap</p>
            </div>
            <div className="desktop-icon">
              <a href="https://t.me/SuperSonSOL" target="_blank" rel="noopener noreferrer">
                <img src={Telegram} alt="Telegram" />
                <p>Telegram</p>
              </a>
            </div>
            <div className="desktop-icon" onClick={() => openApp(<Meme onClose={() => closeWindow(0)} />, 'Meme')}>
              <img src={Memes} alt="Memes" />
              <p>Memes</p>
            </div>
          </div>

          <div className="icon-row">
            <div className="desktop-icon">
              <a href="https://dexscreener.com/solana/5jlfnyuqqleuxyxibpcgx1sm3mkxxewq6aptynwyjwpa" target="_blank" rel="noopener noreferrer">
                <img src={Dexscreener} alt="Dexscrenner" />
                <p>Dexscreener</p>
              </a>
            </div>
            <div className="desktop-icon">
              <a href="https://galiamerchants.com/product-category/all/gm-build/supers/" target="_blank" rel="noopener noreferrer">
                <img src={Merch} alt="Merch" />
                <p>Merch</p>
              </a>
            </div>
            <div className="desktop-icon" onClick={() => openApp(<DoodleJumpWindow />, 'SuperJump', { width: 385, height: 705 })}>
              <img src={Dao} alt="SuperJump" />
              <p>SuperJump</p>
            </div>
          </div>
        </div>

        <div className="jeet-icon">
          <a href="https://sol-incinerator.com/#/" target="_blank" rel="noopener noreferrer">
            <img src={Jeet} alt="Jeet" />
            <p>Jeet</p>
          </a>
        </div>

        {windows.map((window, index) => (
          <ModalWindow key={window.id} onClose={() => closeWindow(window.id)} title={window.title} index={index} initialDimensions={window.initialDimensions}>
            {window.content}
          </ModalWindow>
        ))}
      </ThemeProvider>
    </div>
  );
};

export default App;